import { configureStore } from "@reduxjs/toolkit";
import siteSettingsReducer from './slices/siteSettingsSlice';
import userReducer from './slices/userSlice';
import messageReducer from './slices/messageSlice';
export const store = configureStore({
    reducer: {
        siteSettings: siteSettingsReducer,
        user: userReducer,
        message: messageReducer
    }
})