import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Journey from "../components/Journey";
import SVGHolder from "../components/svgHolder/SVGHolder";
import "../styles/Welcome.scss";
const Welcome = () => {
    return (
        <div className="homepage">
            <Header />
            <main className="homepage-body">
                <Hero />
                <Journey />
                <div className="comming-soon">
                    <h1>
                        New arrivals coming soon
                    </h1>
                    <div className="fixed-carosel">

                        <div className="image-holder">
                            <SVGHolder icon="stack" className=" hero-image-content" />
                            <h5>
                                0-2 Years
                            </h5>
                        </div>
                        <div className="image-holder ">
                            <SVGHolder icon="teddy" className=" hero-image-content" />
                            <h5>
                                2-7 Years
                            </h5>
                        </div>
                        <div className="image-holder ">
                            <SVGHolder icon="smoker" className=" hero-image-content" />
                            <h5>
                                8-12 Years
                            </h5>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Welcome;
