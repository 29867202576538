import React from 'react';
import './messageArray.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getMessage, popMessage } from '../../store/slices/messageSlice';
import MessageStrip from '../messageStrip/MessageStrip';
const MessageArray = () => {
	const messages = useSelector(getMessage);
	const dispatch = useDispatch();
	const removeThisMessage = (event, index) => {
		dispatch(popMessage(index));
	}
	//(event, index) => removeThisMessage(event, index)
	return (
		<div className='message-container'>
			{
				messages.message.map((message, index) => <MessageStrip key={message.id} label={message.content} type={message.type} name={index} onClick={(e) => { removeThisMessage(e, index) }}></MessageStrip>)
			}
		</div>
	)
}

export default MessageArray;