import React from "react";
import { ReactComponent as FbLogo } from "../../images/logo/facebook.svg";
import { ReactComponent as GoogleLogo } from "../../images/logo/google.svg";
import { ReactComponent as WhatsappLogo } from "../../images/logo/whatsapp.svg";
import { ReactComponent as GmailLogo } from "../../images/logo/gmail.svg";
import { ReactComponent as TelephoneLogo } from "../../images/logo/telephone.svg";
import { ReactComponent as LogoPlaceholder } from "../../images/logo/placeholder.svg";
import "./svgHolder.scss";
const SVGHolder = ({ icon, size, className }) => {
    const svgIcon = getIconFromLibrary(icon);
    return (
        <div
            id="svg-icon"
            className={`${size ? size : ""} ${className ? className : ""}`}>
            {svgIcon}
        </div>
    );
};

const getIconFromLibrary = (icon) => {
    if (icon === "facebook") {
        return <FbLogo />;
    } else if (icon === "google") {
        return <GoogleLogo />;
    } else if (icon === "whatsapp") {
        return <WhatsappLogo />;
    } else if (icon === "gmail") {
        return <GmailLogo />;
    } else if (icon === "telephone") {
        return <TelephoneLogo />;
    } else if (icon === "duck-png") {
        return (
            <img
                src={require("../../images/assets/duck.png")}
                className="duck"
                alt="duck"
            />
        );
    } else if (icon === "default") {
        return <LogoPlaceholder />;
    } else if (icon === "teddy") {
        return (
            <img
                alt="teddy"
                className="limitpx teddy"
                loading="lazy"
                src="/images/teddy.png"></img>
        );
    } else if (icon === "duck") {
        return (
            <img
                alt="duck"
                className="limitpx duck"
                loading="lazy"
                src="/images/duck.png"></img>
        );
    } else if (icon === "cat") {
        return (
            <img
                alt="cat"
                className="limitpx duck"
                loading="lazy"
                src="/images/cat.png"></img>
        );
    } else if (icon === "stack") {
        return (
            <img
                alt="duck"
                className="limitpx duck"
                loading="lazy"
                src="/images/stack.png"></img>
        );
    } else if (icon === "snow") {
        return (
            <img
                alt="snowman"
                className="limitpx snow"
                loading="lazy"
                src="/images/snow.png"></img>
        );
    } else if (icon === "robo") {
        return (
            <img
                alt="robo"
                className="limitpx snow"
                loading="lazy"
                src="/images/robo.png"></img>
        );
    } else if (icon === "hello") {
        return (
            <img
                alt="hello"
                className="limitpx"
                loading="lazy"
                src="https://www.softshor.in/static/media/Saly-39.77f9d88f092492f228ed.png"></img>
        );
    } else if (icon === "mobile") {
        return (
            <img
                alt="mobile"
                className="limitpx"
                loading="lazy"
                src="https://www.softshor.in/static/media/Saly-24.38130aa41a1ce8b0d4ee.png"></img>
        );
    } else if(icon === "money"){
        return (
            <img
                src="./images/money.png"
                loading="lazy"
                alt="sample"
                className="sample-image"></img>
        );
    } else if(icon === "smoker"){
        return (
            <img
                src="./images/smoker.png"
                loading="lazy"
                alt="sample"
                className="sample-image"></img>
        );
    } else {
        return (
            <img
                src="https://www.softshor.in/static/media/Saly-13.74025544dc524038a8e2.png"
                loading="lazy"
                alt="sample"
                className="sample-image"></img>
        );
    }
};
export default SVGHolder;
