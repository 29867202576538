import React from "react";
import SVGHolder from "../components/svgHolder/SVGHolder";
import "../styles/Hero.scss";
const Hero = () => {
    return (
        <div className="hero-intro">
            <div className="hero-text">
                <h1>We sell Toys!</h1>
                <div className="title">Helping all sellers to sell online</div>
                <p className="hero-paragraph">
                    Duck & Doe is the one and only player that helps business to
                    sell their toys online on different Platforms without
                    hustle and bustle   .
                </p>
                <a href="tel: 7749819889" className="hero-call-to-action">
                    Get in Touch
                </a>
            </div>
            <div className="hero-image">
                <SVGHolder icon="robo" className={"hero-image-content"} />
            </div>
        </div>
    );
};

export default Hero;
