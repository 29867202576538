import React from 'react'
import './messageStrip.scss'
import PropTypes from 'prop-types'
const MessageStrip = ({ className, label, name, dismissible, type, onClick }) => {
    return (
        <div id={`message-${name}`} className={`messageStrip ${className}`}>
            <div className='label'>
                {label}
            </div>
            <div>
                <button onClick={onClick}><span className="material-symbols-outlined">close</span></button>
            </div>
        </div>
    )
};

MessageStrip.propTypes = {
    class: PropTypes.string,
    label: PropTypes.string,
    dismissible: PropTypes.bool,
    type: PropTypes.string,
    onClick: PropTypes.func,
    name: PropTypes.number.isRequired
};

MessageStrip.defaultProps = {
    className: '',
    name: '',
    label: '',
    dismissible: true,
    type: 'info',
    onClick: () => { }
};

export default MessageStrip;