import React from "react";
import "../styles/Footer.scss";
import SVGHolder from "./svgHolder/SVGHolder";
const Footer = () => (
    <footer className="footer">
        <div className="contact">
            <div>
                <a target="_blank" rel="noreferrer" href="https://wa.me/+917749819889">
                    <SVGHolder icon={"whatsapp"}></SVGHolder>
                </a>
            </div>
            <div>
                <a target="_blank" rel="noreferrer" href="mailto:utkarsh.lal29@gmail.com">
                    <SVGHolder icon={"gmail"}></SVGHolder>
                </a>
            </div>
        </div>
        <div className="copyrights">Ⓒ 2023 Duck&Doe llp.</div>
    </footer>
);

export default Footer;
