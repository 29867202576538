import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import PageNotFound from './containers/PageNotFound';
import Welcome from './containers/Welcome';
import { useEffect } from 'react';
import { getTheme, setTheme } from './store/slices/siteSettingsSlice';
import MessageArray from './components/messageArray/MessageArray';
function App() {
	const dispatch = useDispatch();
	//setting saved theme
	useEffect(() => {
		const currentStoredTheme = localStorage.getItem('theme');
		if (currentStoredTheme) {
			dispatch(setTheme(currentStoredTheme));
		} else {
			localStorage.setItem('theme', 'light');
			dispatch(setTheme('light'));
		}
	}, [dispatch]);
	const theme = useSelector(getTheme);

	return (
		<div id="main-app" theme={theme ? theme : ''}>
			<MessageArray />
			<Router>
				<Routes>
					<Route path="/" element={<Welcome />} />
					<Route path='*' element={<PageNotFound />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
