import React from "react";
import SVGHolder from "../components/svgHolder/SVGHolder";
import '../styles/Journey.scss';
const Journey = () => {
    return (
        <>
            <div className="bg-dot"><span className="journey-title">How we work?</span></div>

            <div className="journey">
                <div className="steps">
                    <div className="steps-in-text">
                        <h2 className="heading">
                            Step 1: Trustworthy Handshake
                        </h2>
                        <p>
                            Join the community of dealers, whole sellers, and retailers for providing the best and swift buying experience to the customers. Enabling competitive pricing by leveraging multiple hyper-localized seller networks. Our team will onboard the sellers and help set profit margins for all the listed products.  A strong communication channel will be created by appointing a relationship manager. Thereby, making all order placement and processing easy and smooth from the get-go.
                        </p>
                    </div>
                    <div className="step-image">
                        <SVGHolder icon="hello" className={"hero-image-content"} />
                    </div>
                </div>
                <div className="steps">
                    <div className="step-image">
                        <SVGHolder icon="mobile" className={"hero-image-content"}></SVGHolder>
                    </div>
                    <div className="steps-in-text">
                        <h2 className="heading">
                            Step 2: Make Digital profile
                        </h2>
                        <div className="detail">
                            Our team helps you build a digital catalog of all the toys to make an online presence on all platforms. Digitization involves the standardization of product information thereby creating optimized advertisement campaigns.
                        </div>
                    </div>
                </div>
                <div className="steps">
                    <div className="steps-in-text">
                        <h2 className="heading">
                            Step 3: Manage Virtual Inventory
                        </h2>
                        <div className="detail">
                            Our team will ensure product listing on multiple marketplaces and manage inventory in sync with customer demand. An integrated inventory management setup where sellers from different locations collaborate to reduce the shipment cost and time would benefit the complete seller network.
                        </div>
                    </div>
                    <div className="step-image">
                        <SVGHolder className={"hero-image-content"}></SVGHolder>
                    </div>
                </div>
                <div className="steps">
                    <div className="step-image">
                        <SVGHolder  icon="money" className={"hero-image-content"}></SVGHolder>
                    </div>
                    <div className="steps-in-text">
                        <h2 className="heading">Step 4: Make Payment</h2>
                        <div className="detail">
                            Using best industry payment gateways and in-time invoice management, the team will ensure that as soon as the products are delivered to the customer and the order is marked as “successfully delivered” the payment to the respective seller will be processed. Incentives based on customer reviews will be provided to encourage all the sellers.
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Journey;
