import React from "react";
import "../styles/Header.scss";
import { Link } from 'react-router-dom';
const Header = () => (
    <div className="header">
        <div className="brand-logo">
            <Link to="/">Duck & Doe</Link>
        </div>
        <div className="actions">
            <a href="tel: +917749819889" className="op1">
                Contact us
            </a>
        </div>
    </div>
);

export default Header;
