import { createSlice } from "@reduxjs/toolkit";

const initialState = []

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        clearMessage: (state, action) => {
            return state = [];
        },
        pushMessage: (state, action) => {
            if (typeof action.payload === 'string') {
                const actualContent = action.payload;
                state.push({ type: 'warning', id: getRandomId(state), content: actualContent })
            } else {
                const formatedMessage = action.payload;
                formatedMessage.id = getRandomId(state, formatedMessage.id);
                state.push(formatedMessage);
            }
            return state;
        },
        popMessage: (state, action) => {
            if (state.length > action.payload && action.payload > -1) {
                state.splice(action.payload, 1);
                return state;
            }
        }
    }
});

const getRandomId = (currentState = [], randId = Math.random() * 1000) => {
    return currentState.filter(item => item.id === randId).length > 0 ? getRandomId(currentState, randId + 1) : randId;
}
export const { clearMessage, pushMessage, popMessage } = messageSlice.actions;
export const getMessage = (state) => state;
export default messageSlice.reducer;