import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    theme: 'light',
};

export const siteSettingsSlice = createSlice({
    name: 'siteSettings',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload;
        }
    }
});

export const { setTheme } = siteSettingsSlice.actions;

export const getTheme = (state) => state.siteSettings.theme;

export default siteSettingsSlice.reducer;